/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useEffect, useState } from 'react';
import { getPopularHotels } from '@utils/services';
import { useCookies } from 'react-cookie';
import useSessionStorage from '@hooks/useSessionStorage';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import { homePageView } from '@events/HomePage';
import HomePage from '@components/HomePage/HomePage';
import { PopularHotel } from '@customTypes/popular-hotel-data';
import { store } from '@context/store';
import Layout from '@components/Layout/Layout';
import { getExperimentValue } from '@utils/amplitudeSSR';
import {
  AMPLITUDE_EXPERIMENT_CONTROL_NAME,
  AMPLITUDE_EXPERIMENT_VARIANT_NAME,
} from '@constants/amplitudeExperiments';
import useSendAmplitudeExposure from '@hooks/useSendAmplitudeExposure';
import { ExperimentType } from '@customTypes/context';

type IndexPageProps = {
  popularHotels: PopularHotel[];
  travelerSeason: ExperimentType;
};

const getPopularHotelsData = async () =>
  getPopularHotels()
    .then((response) => ({
      showPopularHotels: true,
      popularHotels: response?.hotels,
    }))
    .catch(() => ({
      showPopularHotels: false,
      popularHotels: null,
    }));

export async function getServerSideProps(context: any) {
  const popularHotels = await getPopularHotelsData();
  let travelerSeason;
  try {
    travelerSeason = await getExperimentValue<
      | typeof AMPLITUDE_EXPERIMENT_VARIANT_NAME
      | typeof AMPLITUDE_EXPERIMENT_CONTROL_NAME
      | undefined
    >(context, 'traveler-season');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting "traveler-season" value on the homepage');
  }

  return {
    props: {
      ...popularHotels,
      travelerSeason: travelerSeason || null,
    },
  };
}

export default function Home({ popularHotels, travelerSeason }: IndexPageProps) {
  const [homePageViewFired, setHomePageViewFired] = useState<boolean>(false);

  const { getItem } = useSessionStorage();
  const date = getItem(sessionStorageKeys.SMART_CALENDAR_DATE);
  const sessionID = getItem(sessionStorageKeys.SESSION_ID);
  const [cookies] = useCookies();

  const {
    state: { userCityAndState },
  } = useContext(store);

  // GA4 Page View Event
  useEffect(() => {
    if (!homePageViewFired && sessionID) {
      homePageView(cookies.userInformation, sessionID, date);
      setHomePageViewFired(true);
    }
  }, [cookies.userInformation, date, homePageViewFired, sessionID]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useSendAmplitudeExposure({
    flagKey: 'traveler-season',
    validation: !!travelerSeason,
    variant: travelerSeason,
  });

  return (
    <HomePage
      popularHotels={popularHotels}
      userCityAndState={userCityAndState}
      travelerSeason={travelerSeason}
    />
  );
}

Home.getLayout = (page: React.ReactElement, pageProps: IndexPageProps) => (
  <Layout searchBarEnabled={false} travelerSeason={pageProps.travelerSeason}>
    {page}
  </Layout>
);
